import { AnimatePresence } from "framer-motion";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { tambahVisitor } from "./Models/FIrebase";
import Belajar from "./Pages/Belajar";
import Belajarku from "./Pages/Belajarku";
import Donasi from "./Pages/Donasi";
import Home from "./Pages/Home";
import Tentang from "./Pages/Tentang";

function App() {
  if (!sessionStorage.getItem("visited", "yes")) {
    sessionStorage.setItem("visited", "yes");
    tambahVisitor();
  }

  return (
    <Router>
      <Core />
    </Router>
  );
}

function Core() {
  const location = useLocation();
  return (
    <>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route path="/eng/learning-video/deaf-person">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Belajar
                lang="en"
                tuli
                playlistId="PLBioImYh_znru3aMB4WUfEtFMAIuqjx-2"
              />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/eng/learning-video">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Belajar lang="en" />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/eng/my-learning-video">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Belajarku lang="en" />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/eng/about-us">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Tentang lang="en" />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/eng/become-a-volunteer">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Donasi lang="en" />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/eng/">
            <>
              <Header lokasi={location.pathname} lang="en" />
              <Home lang="en" />
              <Footer lang="en" />
            </>
          </Route>
          <Route path="/video-belajar/tuna-rungu">
            <>
              <Header lokasi={location.pathname} />
              <Belajar tuli playlistId="PLBioImYh_znru3aMB4WUfEtFMAIuqjx-2" />
              <Footer />
            </>
          </Route>
          <Route path="/video-belajar">
            <>
              <Header lokasi={location.pathname} />
              <Belajar />
              <Footer />
            </>
          </Route>
          <Route path="/video-belajarku">
            <>
              <Header lokasi={location.pathname} />
              <Belajarku />
              <Footer />
            </>
          </Route>
          <Route path="/tentang-disabisa">
            <>
              <Header lokasi={location.pathname} />
              <Tentang />
              <Footer />
            </>
          </Route>
          <Route path="/jadi-relawan">
            <>
              <Header lokasi={location.pathname} />
              <Donasi />
              <Footer />
            </>
          </Route>
          <Route path="/">
            <>
              <Header lokasi={location.pathname} />
              <Home />
              <Footer />
            </>
          </Route>
          <Route path="*">
            <>
              <Header lokasi={location.pathname} />
              <Home />
              <Footer />
            </>
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
