import { motion } from "framer-motion";

const Donasi = ({ lang = "id" }) => {
  return (
    <div
      className="text-myblack font-poppins"
      style={{
        background: "linear-gradient(180deg, #FFB923 -17.68%, #FEFBD1 18.26%)",
        minHeight: "70vh",
      }}
    >
      <motion.div
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="max-w-7xl mx-auto pb-12 md:pb-20 pt-28 md:pt-32 px-4">
          <p className="text-3xl md:text-5xl font-antapani text-mycolors-100">
            {lang === "en" ? "Take part" : "Ikut Berpartisipasi"}
          </p>
          <p className="texl-lg md:text-xl lg:text-2xl my-14">
            {lang === "en"
              ? "Be a part of Disabisa to keep helping Disabisa friends learn public speaking and be proficient in communication!"
              : `Ikuti Disabisa untuk terus membantu teman-teman Disabisa belajar
            public speaking dan bisa berkomunikasi dengan lebih mahir lagi!`}
          </p>
          <div className="">
            <p className="text-2xl md:text-3xl font-antapani text-mycolors-100">
              {lang === "en" ? "Volunteer Channel" : "Saluran Relawan"}
            </p>
            <p className="text-lg md:text-xl">
              {lang === "en"
                ? "We appreciate any interests towards Disabisa programs. Volunteer registration and any kind of help can be carried out via this form!"
                : `Kami sangat mengapresiasi segala bentuk perhatian kepada
              program-program Disabisa. Pendaftaran relawan dan jenis bantuan
              dapat dilakukan melalui form berikut ini`}
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeXA8Nr1P2muoKkPRmKIVIKPYL5O-sYMP3rdVffklHrASitqQ/viewform"
              className="text-lg md:text-3xl py-2 px-8 rounded-md bg-mycolors-300 font-antapani shadow-md block w-max mt-20 mx-auto"
            >
              {lang === "en"
                ? "Volunteer Registration Form"
                : "Form Pendaftaran Relawan"}
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Donasi;
