// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, Timestamp, query, where, getDocs, updateDoc, doc, increment, onSnapshot } from "firebase/firestore"; 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAn42wpmYvaIIRUmT_XmlhH5JINylb8LhA",
  authDomain: "disabisa.firebaseapp.com",
  projectId: "disabisa",
  storageBucket: "disabisa.appspot.com",
  messagingSenderId: "437409474597",
  appId: "1:437409474597:web:4040816fcd1f6eaf0df7cc",
  measurementId: "G-0QC1QYLZT3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics)

const db = getFirestore();

export const tambahKomentar = async (nama,komentar,id)=>{
    const docRef = await addDoc(collection(db, "comments"), {
        name: nama,
        comment: komentar,
        youtube_id: id,
        created_at: Timestamp.fromDate(new Date()),
    });
    // console.log("sukses tambah komentar",docRef)

    return docRef
}

export const balasKomentar = async (hasil)=>{
    const tabel = doc(db,"comments",hasil.id)

    await updateDoc(tabel,hasil)

    return true
}

export const getkomentar = async (youtubeid)=>{

    const q = query(collection(db, "comments"), where("youtube_id", "==", youtubeid));
    const querySnapshot = await getDocs(q);
    let hasil = []
    querySnapshot.forEach(item=>{
        hasil.push({id:item.id,...item.data()})
    })
    return(hasil)
}

export const tambahVisitor = async ()=>{
    const u = doc(db,"webinfo","1")
    await updateDoc(u,{
        visitors: increment(1)
    })
}

    
onSnapshot(
doc(db, "webinfo", "1"), 
{ includeMetadataChanges: true }, 
(doc) => {
    localStorage.setItem("visitors",doc.data().visitors)
});