import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const AboutUs = ({ lang = "id" }) => {
  return (
    <div className="py-12 lg:py-20 px-4 max-w-7xl mx-auto">
      <p className="text-3xl lg:text-5xl font-antapani text-mycolors-100 text-center">
        {lang === "en" ? "About Us" : "Tentang Kami"}
      </p>
      <p className="text-center text-sm md:text-base lg:text-2xl mt-5 max-w-2xl lg:max-w-4xl mx-auto font-poppins">
        {lang === "en"
          ? `Disabisa is a communication learning website for differently abled people. It is initiated by the Faculty of Communication Science and Center of Teaching and Learning Innovation Universitas Padjajaran, with the support of Directorate General of Higher Education (Ditjen Dikti).`
          : `Disabisa merupakan website pembelajaran komunikasi bagi teman-teman difabel. Inovasi pembelajaran ini digagas oleh Fakultas Ilmu Komunikasi dan Pusat Inovasi Pengajaran dan Pembelajaran Universitas Padjadjaran, dengan dukungan Ditjen Dikti.`}
      </p>
      <Link to={lang === "en" ? "/eng/about-us" : "/tentang-disabisa"}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="font-antapani text-myblack py-2 px-10 rounded-lg shadow-md mx-auto bg-mycolors-300 block mt-8"
        >
          {lang === "en" ? "See More" : "Lihat Selengkapnya"}
        </motion.button>
      </Link>
    </div>
  );
};

export default AboutUs;
