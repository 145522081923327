import { motion } from "framer-motion";
import Gambar from "../../Images/speechfemale.png";

const Belajarku = ({ lang = "id" }) => {
  const isi = [
    "Silakan mempelajari video belajar yang telah Disabisa sediakan di laman Video Belajar",
    "Jika sudah selesai belajar dan siap melakukan praktik, silakan buat video berlatih public speaking maksimal berdurasi 5 menit",
    "Silakan klik Unggah Videoku dan lengkapi form identitas beserta video latihan",
    "Feedback untuk perkembangan kemampuan public speaking-mu akan tim Disabisa kirimkan melalui email setiap tanggal 1 di awal bulan!",
  ];

  const isien = [
    "Watch learning video provided by Disabisa in Learning Video page",
    "Once finished and ready to practice, make a 5-minute public speaking practice video",
    "Click Upload My Video and complete the identity form along with the practice video",
    "Disabisa team will give feedback on your public speaking ability by email every 1st of the month!",
  ];

  return (
    <div
      className="text-myblack font-poppins"
      style={{
        background: "linear-gradient(180deg, #FFB923 -17.68%, #FEFBD1 18.26%)",
      }}
    >
      <motion.div
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="max-w-7xl mx-auto pb-5 md:pb-20 pt-28 md:pt-32 px-4">
          <p className="text-3xl md:text-5xl font-antapani text-mycolors-100">
            {lang === "en" ? "My Learning Video" : "Video Belajarku"}
          </p>
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="flex-1 mt-5 md:mt-10">
              <img
                className="rounded-lg"
                alt="Ilustrasi Upload Video Belajar"
                src={Gambar}
              />
            </div>
            <div className="flex-1 flex flex-col justify-center md:mt-0 mt-5">
              <p className="text-3xl text-center md:text-left lg:text-4xl max-w-md font-antapani">
                {lang === "en"
                  ? "Let’s practice public speaking with Disabisa!"
                  : "Yuk, belajar praktik public speaking bersama Disabisa!"}
              </p>
              <div className="pt-5 lg:pt-10 flex md:block justify-center">
                <motion.a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScScLLm_eloxJPMenzE7hwD7wdxcZraaiVP34fyqNAUKMf4IQ/viewform"
                  className="py-2 px-10 rounded-lg bg-mycolors-300 font-antapani"
                >
                  {lang === "en" ? "Upload My Video" : "Unggah Videoku"}
                </motion.a>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <p className="font-antapani text-2xl mb-4">
              {lang === "en" ? "How To" : "Tata Cara"} :
            </p>
            {lang === "en"
              ? isien.map((item, index) => {
                  return (
                    <div className="py-1 flex space-x-3 md:text-lg lg:text-xl">
                      <p>{index + 1}.</p>
                      <p>{item}</p>
                    </div>
                  );
                })
              : isi.map((item, index) => {
                  return (
                    <div className="py-1 flex space-x-3 md:text-lg lg:text-xl">
                      <p>{index + 1}.</p>
                      <p>{item}</p>
                    </div>
                  );
                })}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Belajarku;
