import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Banner from "../../Images/option-removebg.png"
import { AnimatePresence, motion } from "framer-motion"

const Carousel = () => {


    return(
        <div>
            <AnimatePresence>
                <motion.div className="w-full relative max-w-7xl mx-auto">
                    {/* ukuran 1920 × 1080 */}
                    <img className="w-full" alt="" src={Banner} />
                    <div className="hidden absolute top-0 h-full left-0 flex items-center justify-center px-8">
                        <motion.button whileHover={{scale:1.1}} whileTap={{scale:.9}} className="text-2xl">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </motion.button>
                    </div>
                    <div className="hidden absolute top-0 h-full right-0 flex items-center justify-center px-8">
                        <motion.button whileHover={{scale:1.1}} whileTap={{scale:.9}} className="text-2xl">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </motion.button>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>
    )
}

export default Carousel