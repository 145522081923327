import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Starter = ({ lang = "id" }) => {
  return (
    <div>
      <div className="flex flex-col lg:flex-row w-full max-w-7xl mx-auto pt-32 md:pt-60 pb-5 md:py-20 font-antapani items-center justify-center lg:space-x-8">
        <p className="text-3xl md:text-6xl text-mycolors-100 text-center">
          DISABISA
        </p>
        <p className="font-poppins text-myblack text-lg md:text-2xl max-w-lg text-center">
          {lang === "en"
            ? "Limitations are challenges, not barriers."
            : "Keterbatasan Bisa Menghalangi Kita, Namun Tidak Menghentikan Kita"}
        </p>
      </div>
      <div className="flex flex-col px-10 md:flex-row justify-center gap-5 md:gap-10 lg:gap-20 text-white font-bold md:text-xl w-full max-w-7xl mx-auto mt-5 pb-16 md:pb-40">
        <Link to={lang === "en" ? "/eng/learning-video" : "/video-belajar"}>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-3 rounded-xl bg-mycolors-100 max-w-sm shadow-lg border"
          >
            {lang === "en"
              ? "Learn Public Speaking for Blind & Quadriplegic Person"
              : "Pelatihan Public Speaking Teman Netra & Daksa"}
          </motion.button>
        </Link>
        <Link
          to={
            lang === "en"
              ? "/eng/learning-video/deaf-person"
              : "/video-belajar/tuna-rungu"
          }
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-3 rounded-xl bg-mycolors-100 max-w-sm shadow-lg border"
          >
            {lang === "en"
              ? "Becoming Content Creator for Deaf Person"
              : "Pelatihan Membuat Konten Teman Dengar"}
          </motion.button>
        </Link>
      </div>
    </div>
  );
};

export default Starter;
