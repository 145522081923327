import { motion } from "framer-motion";
import AboutUs from "./aboutus";
import Carousel from "./carousel";
import Donate from "./donate";
import Product from "./product";
import Starter from "./starter";

const Home = ({ lang = "id" }) => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          background:
            "linear-gradient(180deg, #FFB923 -17.68%, #FEFBD1 18.26%)",
        }}
        className=""
      >
        <div className="font-poppins text-myblack">
          <Starter lang={lang} />
          <Carousel lang={lang} />
          <AboutUs lang={lang} />
          <Product lang={lang} />
          <Donate lang={lang} />
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
