import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import Logo from "../../Images/logo.png";
import TextLogo from "../../Images/textlogo.png";
import ReactCountryFlag from "react-country-flag";

const Header = ({ lokasi, lang = "id" }) => {
  const [nav, setNav] = useState(false);
  // const [windowY, setWindowY] = useState(0)
  const [aktif, setAktif] = useState("/");

  useEffect(() => {
    if (
      lokasi === "/video-belajar" ||
      lokasi === "/video-belajar/tuna-rungu" ||
      lokasi === "/video-belajarku" ||
      lokasi === "/jadi-relawan" ||
      lokasi === "/tentang-disabisa" ||
      lokasi === "/eng/learning-video" ||
      lokasi === "/eng/learning-video/deaf-person" ||
      lokasi === "/eng/my-learning-video" ||
      lokasi === "/eng/become-a-volunteer" ||
      lokasi === "/eng/about-us"
    ) {
      setAktif(lokasi);
    } else {
      setAktif("/");
    }
  }, [lokasi]);

  useEffect(() => {
    // function scrollwindow(e){
    //     setWindowY(window.scrollY)
    // }

    function otherClick(e) {
      if (!e.target.dataset.nav) {
        setNav((prev) => {
          if (prev) {
            return false;
          }
        });
      }
    }

    // window.addEventListener("scroll",scrollwindow)
    document.body.addEventListener("click", otherClick);
    return () => {
      // window.removeEventListener("scroll",scrollwindow)
      document.body.removeEventListener("click", otherClick);
    };
  }, []);

  return (
    <div className="w-full md:px-10 px-5 fixed z-20 bg-mycolors-100">
      <div className="mx-auto max-w-7xl flex relative">
        <Link
          to={lang === "en" ? "/eng" : "/"}
          className="flex my-3 items-center py-2 px-6 rounded-full bg-mywhite"
        >
          <img alt="logo disabisa" src={Logo} className="h-8 md:h-10" />
          <img alt="logo disabisa" src={TextLogo} className="h-4 md:h-6" />
        </Link>
        <div className="flex-1 flex justify-end">
          <div className="lg:flex hidden items-center space-x-3 font-antapani">
            <Link
              to={lang === "en" ? "/eng" : "/"}
              className="h-full relative flex items-center px-5"
            >
              <motion.div
                initial={{ scaleY: 0 }}
                animate={{ scaleY: aktif === "/" ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                className="absolute origin-top top-0 left-0 w-full h-5/6 bg-mycolors-200 z-10 rounded-b-lg"
              />
              <motion.button
                className={`font-semibold tracking-wider text-sm z-20 ${
                  aktif === "/" ? "text-myblack" : "text-mywhite"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Home
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/learning-video" : "/video-belajar"}
              className="h-full relative flex items-center px-5"
            >
              <motion.div
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY:
                    aktif === "/video-belajar" ||
                    aktif === "/video-belajar/tuna-rungu" ||
                    aktif === "/eng/learning-video/deaf-person" ||
                    aktif === "/eng/learning-video"
                      ? 1
                      : 0,
                }}
                transition={{ duration: 0.3 }}
                className="absolute origin-top top-0 left-0 w-full h-5/6 bg-mycolors-200 z-10 rounded-b-lg"
              />
              <motion.button
                className={`font-semibold tracking-wider text-sm z-20 ${
                  aktif === "/video-belajar" ||
                  aktif === "/video-belajar/tuna-rungu" ||
                  aktif === "/eng/learning-video/deaf-person" ||
                  aktif === "/eng/learning-video"
                    ? "text-myblack"
                    : "text-mywhite"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "Learning Video" : "Video Belajar"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/my-learning-video" : "/video-belajarku"}
              className="h-full relative flex items-center px-5"
            >
              <motion.div
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY:
                    aktif === "/video-belajarku" ||
                    aktif === "/eng/my-learning-video"
                      ? 1
                      : 0,
                }}
                transition={{ duration: 0.3 }}
                className="absolute origin-top top-0 left-0 w-full h-5/6 bg-mycolors-200 z-10 rounded-b-lg"
              />
              <motion.button
                className={`font-semibold tracking-wider text-sm z-20 ${
                  aktif === "/video-belajarku" ||
                  aktif === "/eng/my-learning-video"
                    ? "text-myblack"
                    : "text-mywhite"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "My Learning Video" : "Video Belajarku"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/become-a-volunteer" : "/jadi-relawan"}
              className="h-full relative flex items-center px-5"
            >
              <motion.div
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY:
                    aktif === "/jadi-relawan" ||
                    aktif === "/eng/become-a-volunteer"
                      ? 1
                      : 0,
                }}
                transition={{ duration: 0.3 }}
                className="absolute origin-top top-0 left-0 w-full h-5/6 bg-mycolors-200 z-10 rounded-b-lg"
              />
              <motion.button
                className={`font-semibold tracking-wider text-sm z-20 ${
                  aktif === "/jadi-relawan" ||
                  aktif === "/eng/become-a-volunteer"
                    ? "text-myblack"
                    : "text-mywhite"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "Become a Volunteer" : "Jadi Relawan"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/about-us" : "/tentang-disabisa"}
              className="h-full relative flex items-center px-5"
            >
              <motion.div
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY:
                    aktif === "/tentang-disabisa" || aktif === "/eng/about-us"
                      ? 1
                      : 0,
                }}
                transition={{ duration: 0.3 }}
                className="absolute origin-top top-0 left-0 w-full h-5/6 bg-mycolors-200 z-10 rounded-b-lg"
              />
              <motion.button
                className={`font-semibold tracking-wider text-sm z-20 ${
                  aktif === "/tentang-disabisa" || aktif === "/eng/about-us"
                    ? "text-myblack"
                    : "text-mywhite"
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "About Us" : "Tentang Kami"}
              </motion.button>
            </Link>
            <Link to={lang === "en" ? "/" : "/eng"}>
              <ReactCountryFlag
                countryCode={lang === "en" ? "ID" : "GB"}
                className="text-2xl"
                svg
              />
            </Link>
          </div>
          <div className="lg:hidden flex items-center">
            <motion.button
              data-nav
              onClick={() => {
                setNav(true);
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="py-1 px-2 border-2 text-mywhite border-mywhite rounded-md"
            >
              <FontAwesomeIcon data-nav icon={faBars} />
            </motion.button>
          </div>
        </div>
        <motion.div
          data-nav
          initial={{ scale: 0 }}
          animate={{ scale: nav ? 1 : 0 }}
          className="lg:hidden origin-top-right mt-2 text-myblack absolute shadow-lg rounded-lg bg-white w-full z-30 p-5"
        >
          <div data-nav className="flex flex-col space-y-2">
            <div data-nav className="w-full flex justify-end">
              <motion.button
                onClick={() => setNav(false)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </motion.button>
            </div>
            <Link to={lang === "en" ? "/eng" : "/"} className="w-full">
              <motion.button
                className="font-antapani"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Home
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/learning-video" : "/video-belajar"}
              className="w-full"
            >
              <motion.button
                className="font-antapani"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "Learning Video" : "Video Belajar"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/my-learning-video" : "/video-belajarku"}
              className="w-full"
            >
              <motion.button
                className="font-antapani"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "My Learning Video" : "Video Belajarku"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/become-a-volunteer" : "/jadi-relawan"}
              className="w-full"
            >
              <motion.button
                className="font-antapani"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "Become a Volunteer" : "Jadi Relawan"}
              </motion.button>
            </Link>
            <Link
              to={lang === "en" ? "/eng/about-us" : "/tentang-disabisa"}
              className="w-full"
            >
              <motion.button
                className="font-antapani"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {lang === "en" ? "About Us" : "Tentang Kami"}
              </motion.button>
            </Link>
            <Link to={lang === "en" ? "/" : "/eng"}>
              <motion.button
                className="font-antapani flex items-center"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <ReactCountryFlag
                  countryCode={lang === "en" ? "ID" : "GB"}
                  className="text-2xl mr-2"
                  svg
                />
                {lang === "en" ? "Bahasa" : "English"}
              </motion.button>
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Header;
