import { motion } from "framer-motion";
import BuIra from "../../Images/tim/bu ira.jpg";
import BuHerlina from "../../Images/tim/bu herlina.jpg";
import BuPutri from "../../Images/tim/bu putri.jpg";
import PakJimi from "../../Images/tim/pak jimi.jpg";

const Tentang = ({ lang = "id" }) => {
  const list = [
    {
      nama: "Dr. Ira Mirawati, M.Si ",
      peran: "Managing Director",
      image: BuIra,
    },
    {
      nama: "Putri Limilia, M.Si",
      peran: "Content Manager",
      image: BuPutri,
    },
    {
      nama: "Jimi Narotama Mahameruaji, M.Si",
      peran: "Production Manager",
      image: PakJimi,
    },
    {
      nama: "Dr. Herlina Agustin, M.T ",
      peran: "Difabel Relation Manager",
      image: BuHerlina,
    },
  ];

  const renderMember = (item, index) => {
    return (
      <div
        key={index}
        className="bg-transparent px-4 md:pl-0 md:pr-16 py-5 w-1/2 md:w-1/3"
      >
        <div className="text-center">
          <img alt="" className="rounded-md" src={item.image} />
          <p className="mt-2 font-bold text-sm md:text-xl">{item.nama}</p>
          <p className="text-sm md:text-base">{item.peran}</p>
        </div>
      </div>
    );
  };

  return (
    <div
      className="text-myblack font-poppins"
      style={{
        background: "linear-gradient(180deg, #FFB923 -17.68%, #FEFBD1 18.26%)",
      }}
    >
      <motion.div
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="max-w-7xl mx-auto pb-12 md:pb-20 pt-28 md:pt-32 px-4">
          <p className="text-3xl md:text-5xl font-antapani text-mycolors-100">
            {lang === "en" ? "About Us" : "Tentang Kami"}
          </p>
          <p className="texl-lg md:text-xl lg:text-2xl my-14">
            {lang === "en"
              ? "Disabisa is a communication learning website for differently abled people. It is initiated by the Faculty of Communication Science and Center of Teaching and Learning Innovation at Universitas Padjajaran, with the support of Directorate General of Higher Education (Ditjen Dikti)."
              : "Disabisa merupakan website pembelajaran komunikasi bagi teman-teman difabel. Inovasi pembelajaran ini digagas oleh Fakultas Ilmu Komunikasi dan Pusat Inovasi Pengajaran dan Pembelajaran Universitas Padjadjaran, dengan dukungan Ditjen Dikti."}
          </p>
          <div className="my-8 md:text-xl">
            <p className="font-semibold text-xl md:text-2xl">
              {lang === "en"
                ? "Hopefully this website can :"
                : "Website ini diharapkan dapat :"}
            </p>
            <ul className="list-disc pl-5 mt-3 space-y-2">
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Make blind and physically disabled people aware of their potential to become reliable speakers"
                    : `Menyadarkan teman-teman difabel netra dan daksa akan potensi mereka untuk dapat menjadi pembicara andal`}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Help blind and physically disabled people realize their strengths"
                    : "Membantu teman-teman difabel netra dan daksa menemukan kelebihan dirinya masing-masing"}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Guide blind and physically disabled people to practice compiling public speaking materials"
                    : "Menuntun teman-teman difabel netra dan daksa untuk berlatih menyusun materi public speaking"}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Guide blind and physically disabled people to practice vocals"
                    : "Menuntun teman-teman difabel netra dan daksa untuk praktik berlatih vokal"}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Guide blind and physically disabled people to use the power of nonverbal language"
                    : "Menuntun teman-teman difabel netra dan daksa untuk praktik menggunakan kekuatan bahasa nonverbal"}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Guide blind and physically disabled people to practice an engaging opening speech"
                    : "Menuntun teman-teman difabel netra dan daksa berlatih membuka pidato dengan menarik"}
                </span>
              </li>
              <li className="text-mycolors-100">
                <span className="text-myblack">
                  {lang === "en"
                    ? "Guide blind and physically disabled people to practice an engaging closing speech"
                    : "Menuntun teman-teman difabel netra dan daksa berlatih menutup pidato dengan menarik"}
                </span>
              </li>
            </ul>
          </div>
          <p className="text-2xl md:text-4xl font-antapani mt-5 md:mt-10 lg:mt-14">
            {lang === "en" ? "Disabisa Management" : "Pengelola Disabisa"}
          </p>
          <div className="flex flex-wrap mt-8">{list.map(renderMember)}</div>
          <p className="mt-4 md:mt-12 mb-14 md:mb-20 md:text-xl lg:text-2xl">
            {lang === "en"
              ? "This website is developed by a team of students and Unpad Directorate of Planning and Information System"
              : "Website dikembangkan oleh tim mahasiswa dan DPSI Unpad"}
          </p>
          <div>
            <p className="text-3xl md:text-5xl font-antapani text-mycolors-100">
              {lang === "en" ? "Contact Us" : "Kontak Kami"}
            </p>
            <p className="mt-8 text-lg md:text-2xl">
              Dr. Ira Mirawati, M.Si
              <br />
              {lang === "en"
                ? "Building 3, 1st Floor, Faculty of Communication Science"
                : "Gedung 3 Lantai 1 Fakultas Ilmu Komunikasi"}
              <br />
              {lang === "en"
                ? "Jalan Raya Bandung-Sumedang km 21"
                : "Jln. Raya Bandung-Sumedang Km 21 Jatinangor"}
              <br />
              40563
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Tentang;
