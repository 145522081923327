import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

const Footer = ({ lang = "id" }) => {
  let lokal = localStorage.getItem("visitors");
  const [visitors, setVisitors] = useState(lokal ? lokal : 0);

  useEffect(() => {
    setVisitors(lokal ? lokal : 0);
  }, [lokal]);

  return (
    <div className="bg-mycolors-100 pt-8 pb-20">
      <div className="max-w-7xl md:px-10 px-5 mx-auto">
        <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row">
          <div className="flex-1 flex flex-col">
            <div className="flex-1">
              <p className="text-mycolors-200 font-antapani text-3xl">
                Related Links
              </p>
              <div className="flex mt-4 lg:text-xl">
                <div className="flex space-y-1 flex-1 flex-col">
                  <a href="http://repository.unpad.ac.id/">Repository</a>
                  <a href="http://lib.unpad.ac.id/">Library</a>
                  <a href="http://paus.unpad.ac.id/">PAUS ID</a>
                </div>
                <div className="flex space-y-1 flex-1 flex-col">
                  <a href="https://sobatmu.com/">
                    {lang === "en"
                      ? "Consultation Service"
                      : "Layanan Konsultasi"}
                  </a>
                  <a href="http://unpad.ac.id/">Universitas Padjajaran</a>
                  <a href="http://fikom.unpad.ac.id/">
                    {lang === "en"
                      ? "UNPAD Faculty of Communication Science"
                      : "Fikom UNPAD"}
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-mycolors-200 hidden lg:block rounded-full py-1 px-4 font-semibold w-max">
                <p>
                  {lang === "en" ? "Visitors" : "Jumlah Pengunjung"} :{" "}
                  {visitors}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <p className="text-mycolors-200 font-antapani text-3xl">
              Contact Us
            </p>
            <div className="mt-4 space-y-4">
              <div className="flex items-center space-x-2 md:text-xl lg:text-2xl">
                <FontAwesomeIcon icon={faEnvelope} className="mt-1" />
                <p>kaprodi.mankom@unpad.ac.id</p>
              </div>
              <div className="flex items-center space-x-2 md:text-xl lg:text-2xl">
                <FontAwesomeIcon
                  icon={faPhone}
                  flip="horizontal"
                  className="mt-1"
                />
                <p>022-7796954</p>
              </div>
              <div className="flex items-start space-x-2  text-sm lg:text-2xl">
                <FontAwesomeIcon icon={faHome} className="mt-2" />
                <p>
                  Prodi Manajemen Komunikasi Gedung 3 Lt.1
                  <br />
                  Kampus Fikom Unpad
                  <br />
                  Jln. Raya Bandung-Sumedang Km.21 Jatinangor
                </p>
              </div>
            </div>
            <div className="bg-mycolors-200 mt-4 lg:hidden rounded-full py-1 px-4 font-semibold w-max">
              <p>
                {lang === "en" ? "Visitors" : "Jumlah Pengunjung"} : {visitors}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
