import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import {
  balasKomentar,
  getkomentar,
  tambahKomentar,
} from "../../Models/FIrebase";
import { getPlaylistList } from "../../Models/Youtube";
import Logo from "../../Images/logo.png";
import { Link } from "react-router-dom";

const Belajar = ({
  lang = "id",
  tuli,
  playlistId = "PL3lqadcUNHtjrijgyB5axV4kVb_ch2n7Y",
}) => {
  const [video, setVideo] = useState([]);
  const [komentars, setKomentars] = useState([]);
  const [komentarUpdate, setKomentarUpdate] = useState(0);
  const [komentPage, setKomentPage] = useState([]);
  const komentperpage = 20;
  const kodeadmin = "admindisabisa";
  const [isAdmin, setIsAdmin] = useState(false);

  const [nextToken, setNextToken] = useState("");
  const [prevToken, setPrevToken] = useState("");

  const videoRender = (item, index) => {
    return (
      <div key={index}>
        <div className="video-container mt-5 md:mt-12 rounded-lg">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${item.contentDetails.videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p className="text-xl md:text-3xl font-antapani mt-4 md:mt-8">
          {item.snippet.title}
        </p>
        <div className="rounded-lg flex flex-col md:flex-row bg-mycolors-200 mt-5 p-8 md:space-x-20">
          <div className="flex-1">
            <div
              className="space-y-4 overflow-y-auto"
              style={{ maxHeight: 352 }}
            >
              {komentars.filter((filter) => {
                if (filter.youtube_id === item.contentDetails.videoId) {
                  return filter;
                } else {
                  return null;
                }
              }).length > 0 ? (
                komentars
                  .filter((filter) => {
                    if (filter.youtube_id === item.contentDetails.videoId) {
                      return filter;
                    } else {
                      return null;
                    }
                  })
                  .sort((a, b) => {
                    return b.created_at.seconds - a.created_at.seconds;
                  })
                  .map((itemmap, indexmap) => {
                    let tglmap = new Date(itemmap.created_at.seconds * 1000);
                    if (
                      indexmap + 1 <=
                      komentperpage *
                        komentPage.find((find) => {
                          return find.kode === item.contentDetails.videoId;
                        }).page
                    ) {
                      return (
                        <div key={indexmap}>
                          <p className="my-3 px-4">
                            {itemmap.name}
                            <br />
                            {`${
                              tglmap.getDate() < 10
                                ? "0" + tglmap.getDate()
                                : tglmap.getDate()
                            }/${
                              tglmap.getMonth() + 1 < 10
                                ? "0" + (tglmap.getMonth() + 1)
                                : tglmap.getMonth() + 1
                            }/${tglmap.getFullYear()} ${
                              tglmap.getHours() < 10
                                ? "0" + tglmap.getHours()
                                : tglmap.getHours()
                            }:${
                              tglmap.getMinutes() < 10
                                ? "0" + tglmap.getMinutes()
                                : tglmap.getMinutes()
                            }`}
                          </p>
                          <p className="p-4 rounded-lg font-antapani bg-mywhite shadow-md whitespace-pre-line">
                            {itemmap.comment}
                          </p>
                          <div className="mt-5 space-y-5">
                            {itemmap.komentar ? (
                              itemmap.komentar.map(
                                (komenadmin, indexkomenadmin) => {
                                  return (
                                    <div
                                      key={indexkomenadmin}
                                      className="flex space-x-5 items-center"
                                    >
                                      <div className="rounded-full h-full p-2 bg-mywhite">
                                        <img
                                          alt="admin disabisa"
                                          src={Logo}
                                          className="h-10 w-10"
                                        />
                                      </div>
                                      <div className="flex-1">
                                        <p className="font-antapani">
                                          Admin Disabisa
                                        </p>
                                        <p className="p-4 rounded-lg font-antapani bg-mywhite shadow-md whitespace-pre-line">
                                          {komenadmin}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                          {isAdmin && (
                            <div className="mt-5">
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  adminkomen(itemmap);
                                }}
                              >
                                <textarea
                                  placeholder="Balasan Komentar..."
                                  style={{ minHeight: 100 }}
                                  className={`px-4 py-2 bg-mywhite shadow-md rounded-lg block w-full mt-3 balaskomentar-${itemmap.id}`}
                                ></textarea>
                                <motion.button
                                  type="submit"
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  className={`py-2 px-8 rounded-full shadow-md block ml-auto md:mr-0 mr-auto bg-mycolors-300 font-bold mt-4 md:mt-6 balasbutton-${itemmap.id}`}
                                >
                                  Kirim Balasan
                                </motion.button>
                              </form>
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
              ) : (
                <div>
                  <p> {lang === "en" ? "No comment" : "Belum ada komentar"}</p>
                </div>
              )}
            </div>
            <div className="hidden">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="flex items-center font-antapani justify-end w-full"
              >
                <p>Selanjutnya</p>{" "}
                <FontAwesomeIcon
                  className="mt-1 block ml-1"
                  icon={faChevronCircleRight}
                />
              </motion.button>
            </div>
          </div>
          <div className="flex-1 md:mt-0 mt-5">
            <div className="flex justify-center md:justify-end">
              <p className="font-bold text-xl rounded-md text-mywhite shadow-lg bg-mycolors-100 py-2 px-8">
                Comments
              </p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitKomentar(index, item.contentDetails.videoId);
              }}
              className="my-4"
            >
              <input
                onChange={(e) => {
                  if (e.target.value === kodeadmin) {
                    setIsAdmin(true);
                  } else {
                    setIsAdmin(false);
                  }
                }}
                className={`px-4 py-2 bg-mywhite shadow-md rounded-lg block w-full nama-${index}`}
                placeholder={
                  lang === "en" ? "Input your name..." : "Masukkan nama kamu..."
                }
              />
              <textarea
                placeholder={
                  lang === "en" ? "Your Comment..." : "Komentar kamu..."
                }
                style={{ minHeight: 100 }}
                className={`px-4 py-2 bg-mywhite shadow-md rounded-lg block w-full mt-3 komentar-${index}`}
              ></textarea>
              <p className="font-antapani text-sm md:text-base md:px-8 mt-3">
                {lang === "en"
                  ? "Disabisa team will reply to your comment shortly"
                  : "komentarmu akan dibalas oleh tim Disabisa secepatnya!"}
              </p>
              <motion.button
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`py-2 px-8 rounded-full shadow-md block ml-auto md:mr-0 mr-auto bg-mycolors-300 font-bold mt-8 md:mt-12 button-${index}`}
              >
                {lang === "en" ? "Save Comment" : "Simpan Komentar"}
              </motion.button>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const changePage = (token) => {
    getPlaylistList({ pageToken: token, playlistId: playlistId }).then(
      (res) => {
        // console.log(res);
        if (res.nextPageToken) {
          setNextToken(res.nextPageToken);
        } else {
          setNextToken("");
        }
        if (res.prevPageToken) {
          setPrevToken(res.prevPageToken);
        } else {
          setPrevToken("");
        }
        setVideo(
          res.items.filter((item) => {
            if (item.contentDetails.videoId && item.snippet.title) {
              return item;
            } else {
              return null;
            }
          })
        );
      }
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getPlaylistList({ playlistId: playlistId }).then((res) => {
      console.log(res);
      if (res.nextPageToken) {
        setNextToken(res.nextPageToken);
      } else {
        setNextToken("");
      }
      if (res.prevPageToken) {
        setPrevToken(res.prevPageToken);
      } else {
        setPrevToken("");
      }
      setVideo(
        res.items.filter((item) => {
          if (item.contentDetails.videoId && item.snippet.title) {
            return item;
          } else {
            return null;
          }
        })
      );
    });
  }, [playlistId]);

  useEffect(() => {
    setKomentPage([]);
    video.forEach((item) => {
      setKomentPage((prev) => {
        return [...prev, { kode: item.contentDetails.videoId, page: 1 }];
      });
    });
  }, [video]);

  useEffect(() => {
    setKomentars([]);
    video.forEach((item) => {
      getkomentar(item.contentDetails.videoId).then((res) => {
        res.forEach((itema) => {
          setKomentars((prev) => {
            return [...prev, itema];
          });
        });
      });
    });
  }, [video, komentarUpdate]);

  const submitKomentar = async (index, yutubid) => {
    let nama = document.querySelector(`.nama-${index}`);
    let komentar = document.querySelector(`.komentar-${index}`);
    let button = document.querySelector(`.button-${index}`);

    nama.disabled = true;
    komentar.disabled = true;
    button.disabled = true;
    button.innerHTML = "Menyimpan...";
    await tambahKomentar(nama.value, komentar.value, yutubid);
    nama.value = "";
    komentar.value = "";
    nama.disabled = false;
    komentar.disabled = false;
    button.disabled = false;
    button.innerHTML = "Simpan Komentar";
    setKomentarUpdate((prev) => prev + 1);
  };

  const adminkomen = async (item) => {
    let hasil;
    document.querySelector(`.balaskomentar-${item.id}`).disabled = true;
    document.querySelector(`.balasbutton-${item.id}`).disabled = true;
    document.querySelector(`.balasbutton-${item.id}`).innerHTML = "Mengirim";
    if (item.komentar) {
      hasil = {
        ...item,
        komentar: [
          ...item.komentar,
          document.querySelector(`.balaskomentar-${item.id}`).value,
        ],
      };
    } else {
      hasil = {
        ...item,
        komentar: [document.querySelector(`.balaskomentar-${item.id}`).value],
      };
    }
    balasKomentar(hasil);
    document.querySelector(`.balaskomentar-${item.id}`).disabled = false;
    document.querySelector(`.balasbutton-${item.id}`).disabled = false;
    document.querySelector(`.balasbutton-${item.id}`).innerHTML =
      "Kirim Balasan";
    setKomentarUpdate((prev) => prev + 1);
  };

  return (
    <div
      className="text-myblack font-poppins"
      style={{
        background: "linear-gradient(180deg, #FFB923 -17.68%, #FEFBD1 18.26%)",
      }}
    >
      <motion.div
        transition={{ duration: 0.3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="max-w-7xl mx-auto pb-12 md:pb-20 pt-28 md:pt-32 px-4">
          <div className="">
            <p className="text-2xl md:text-3xl lg:text-5xl font-antapani text-mycolors-100 block">
              {lang === "en"
                ? !tuli
                  ? "Learn Public Speaking for Blind & Quadriplegic Person"
                  : "Becoming Content Creator for Deaf Person"
                : !tuli
                ? "Berlatih Public Speaking untuk Tunanetra & Daksa"
                : "Berlatih menjadi Content Creator untuk Tunarungu"}
            </p>
            <div className="flex justify-end">
              <Link
                to={
                  lang === "en"
                    ? tuli
                      ? "/eng/learning-video"
                      : "/eng/learning-video/deaf-person"
                    : tuli
                    ? "/video-belajar"
                    : "/video-belajar/tuna-rungu"
                }
                className=""
              >
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="py-2 px-8 rounded-full shadow-md block bg-mycolors-100 font-bold mt-8 md:mt-12 text-white text-left"
                >
                  {/* <FontAwesomeIcon icon={faDeaf} /> */}
                  <p className="text-sm md:text-base lg:text-lg font-semibold md:font-bold flex items-center">
                    <p>
                      {lang === "en"
                        ? tuli
                          ? "Learn Public Speaking for Blind & Quadriplegic Person"
                          : "Becoming Content Creator for Deaf Person"
                        : tuli
                        ? "Berlatih Public Speaking untuk Tunanetra & Daksa"
                        : "Berlatih menjadi Content Creator untuk Tunarungu"}
                    </p>
                    <FontAwesomeIcon
                      className="md:ml-2"
                      icon={faChevronCircleRight}
                    />
                  </p>
                </motion.button>
              </Link>
            </div>
          </div>
          <div>{video.map(videoRender)}</div>
          <div className="mt-5 flex">
            {prevToken !== "" && (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  changePage(prevToken);
                }}
                className="mx-auto block py-2 px-10 rounded-lg shadow-lg bg-mycolors-300 font-antapani"
              >
                Sebelumnya
              </motion.button>
            )}
            {nextToken !== "" && (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  changePage(nextToken);
                }}
                className="mx-auto block py-2 px-10 rounded-lg shadow-lg bg-mycolors-300 font-antapani"
              >
                Selanjutnya
              </motion.button>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Belajar;
