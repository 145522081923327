export const getPlaylistList = async ({
  pageToken = "",
  playlistId = "PL3lqadcUNHtjrijgyB5axV4kVb_ch2n7Y",
}) => {
  let url = "https://www.googleapis.com/youtube/v3/playlistItems";
  url = url + `?part=contentDetails,snippet`;
  url = url + `&playlistId=${playlistId}`;
  // url = url + `&playlistId=PLKIEFFgNQYpVmUAKUjT_BRYYOdMHwGt0v` //test page
  url = url + `&key=AIzaSyCWDX0hPP6Mzh8X3j7wxJFyiwlA5QiL7W0`;
  if (pageToken !== "") {
    url = url + `&pageToken=${pageToken}`;
  }
  let resa = await fetch(url, {
    headers: {
      Accept: "application/json",
    },
  });

  let resb = await resa.json();

  let resc = await resb;

  return resc;
};
