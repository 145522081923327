import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import UnggahHome from "../../Images/speechmale.png";

const Product = ({ lang = "id" }) => {
  return (
    <>
      <div className="lg:px-10 px-5 py-10 max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex-1 text-center order-2 md:order-1">
            <p className="mt-4 md:mt-0 font-antapani text-mycolors-100 text-2xl lg:text-3xl">
              {lang === "en"
                ? "Practicing Public Speaking"
                : "Berlatih Public Speaking"}
              <br />
              {lang === "en"
                ? "through Learning Video"
                : "Lewat Video Pembelajaran"}
            </p>
            <p className="my-2 text-sm md:text-lg md:my-8 max-w-sm mx-auto">
              {lang === "en"
                ? "Learn the basics of public speaking step-by-step by watching Disabisa learning videos"
                : `Belajar secara bertahap mengenai dasar-dasar public speaking
              dengan menonton video pembelajaran Disabisa`}
            </p>
            <Link to={lang === "en" ? "/eng/learning-video" : "/video-belajar"}>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="mt-3 md:mt-0 py-2 px-8 rounded-md bg-mycolors-300 font-antapani shadow-md"
              >
                {lang === "en" ? "Start Learning" : "Mulai Belajar"}
              </motion.button>
            </Link>
          </div>
          <div className="flex-1 order-1 md:order-2">
            <div className="video-container rounded-lg">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/U80oermX0_g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:px-10 px-5 py-10 max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row md:space-x-7 items-center">
          <div className="flex-1">
            <img className="w-full rounded-lg" alt="" src={UnggahHome} />
          </div>
          <div className="flex-1 text-center">
            <p className="font-antapani text-mycolors-100 mt-3 md:mt-0 text-xl md:text-2xl lg:text-3xl">
              {lang === "en"
                ? "Upload your practice video!"
                : "Unggah video latihanmu sendiri!"}
            </p>
            <p className="my-2 md:my-4 lg:my-6 text-sm md:text-lg mx-auto max-w-sm">
              {lang === "en"
                ? "Encourage yourself to practice Disabisa by recording your public speaking video"
                : `Yuk beranikan diri mempraktikkan ilmu dari Disabisa dengan membuat
              rekaman public speaking sendiri`}
            </p>
            <Link
              to={lang === "en" ? "/eng/my-learning-video" : "/video-belajarku"}
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="mt-3 md:mt-0 py-2 px-8 rounded-md bg-mycolors-300 font-antapani shadow-md"
              >
                {lang === "en" ? "I Want to Try" : "Aku Ingin Coba"}
              </motion.button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
