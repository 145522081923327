import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Donate = ({ lang = "id" }) => {
  return (
    <div className="pb-20 pt-12 md:pt-20 md:pb-40">
      <p className="text-center text-xl md:text-4xl font-antapani text-mycolors-100">
        {lang === "en"
          ? "You can help Disabisa friends to learn!"
          : "Kamu juga bisa membantu teman Disabisa belajar!"}
      </p>
      <p className="text-center my-7 md:text-lg max-w-4xl mx-auto">
        {lang === "en"
          ? "Become a volunteer to support their learning experience."
          : "Dengan menjadi relawan yang mendukung pembelajaran teman-teman difabel."}
      </p>
      <Link
        className="block w-max mx-auto"
        to={lang === "en" ? "/eng/become-a-volunteer" : "/jadi-relawan"}
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="mx-auto block py-2 px-10 rounded-lg shadow-lg bg-mycolors-300 font-antapani"
        >
          {lang === "en" ? "I Want to Volunteer" : "Aku Mau Jadi Relawan"}
        </motion.button>
      </Link>
    </div>
  );
};

export default Donate;
